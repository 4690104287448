import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "EIS Chapters Heading",
    moduleComponentName: "SectionHeadingWithButtons",
    rowProps: {
      className: "py-3 bg-white text-primary",
      noGutters: true,
    },
    moduleProps: {
      sectionHeadingProps: {
        heading: {
          priority: 2,
          style: {
            fontWeight: "bold",
          },
          text: "Draft Environmental Impact Assessment documents",
        },
        showClosedSubmissionAlert: false,
      },
      buttons: [
        {
          text: "Summary",
          props: {
            variant: "primary",
            href: "#summary",
          },
        },
        {
          text: "Part A Background",
          props: {
            variant: "primary",
            href: "#part-a",
          },
        },
        {
          text: "Part B	The project",
          props: {
            variant: "primary",
            href: "#part-b",
          },
        },
        {
          text: "Part C	Environmental impact assessment",
          props: {
            variant: "primary",
            href: "#part-c",
          },
        },
        {
          text: "Part D	EIS synthesis",
          props: {
            variant: "primary",
            href: "#part-d",
          },
        },
        {
          text: "Technical papers",
          props: {
            variant: "primary",
            href: "#technical-papers",
          },
        },
        {
          text: "Hard copy viewing locations",
          props: {
            variant: "primary",
            href: "#eis-viewing-locations",
          },
        },
      ],
    },
    wrapWithContainer: true,
  },
  {
    name: "Executive summary",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Summary",
      groupHeadingId: "summary",
      chapters: [
        {
          chapter: "Summary",
          tableFiles: [
            {
              title: "Summary",
              document: "WSI_EIS_Summary",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Part A Background",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Part A Background",
      groupHeadingId: "part-a",
      consolidatedChapters: {
        title: "Complete Part A - Background",
        document: "WSI_EIS_Part_A_Background",
      },
      chapters: [
        {
          chapter: "Chapter 1",
          tableFiles: [
            {
              title: "Introduction",
              document: "WSI_EIS_Chapter_1_Introduction",
            },
          ],
        },
        {
          chapter: "Chapter 2",
          tableFiles: [
            {
              title: "Strategic context and need",
              document: "WSI_EIS_Chapter_2_Strategic_context_and_need",
            },
          ],
        },
        {
          chapter: "Chapter 3",
          tableFiles: [
            {
              title: "Introduction to airspace",
              document: "WSI_EIS_Chapter_3_Introduction_to_airspace",
            },
          ],
        },
        {
          chapter: "Chapter 4",
          tableFiles: [
            {
              title: "Project setting",
              document: "WSI_EIS_Chapter_4_Project_setting",
            },
          ],
        },
        {
          chapter: "Chapter 5",
          tableFiles: [
            {
              title: "Statutory context",
              document: "WSI_EIS_Chapter_5_Statutory_context",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Part B The project",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Part B The project",
      groupHeadingId: "part-b",
      consolidatedChapters: {
        title: "Complete Part B - The Project",
        document: "WSI_EIS_Part_B_The_project",
      },
      chapters: [
        {
          chapter: "Chapter 6",
          tableFiles: [
            {
              title: "Project development and alternatives",
              document:
                "WSI_EIS_Chapter_6_Project_development_and_alternatives",
            },
          ],
        },
        {
          chapter: "Chapter 7",
          tableFiles: [
            {
              title: "The project",
              document: "WSI_EIS_Chapter_7_The_project",
            },
          ],
        },
        {
          chapter: "Chapter 8",
          tableFiles: [
            {
              title: "Facilitated changes",
              document: "WSI_EIS_Chapter_8_Facilitated_changes",
            },
          ],
        },
        {
          chapter: "Chapter 9",
          tableFiles: [
            {
              title: "Community and stakeholder engagement",
              document:
                "WSI_EIS_Chapter_9_Community_and_stakeholder_engagement",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Part C	Environmental impact assessment",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Part C	Environmental impact assessment",
      groupHeadingId: "part-c",
      consolidatedChapters: {
        title: "Complete Part C - Environmental Impact Assessment",
        document: "WSI_EIS_Part_C_Environmental_impact_assessment",
      },
      chapters: [
        {
          chapter: "Chapter 10",
          tableFiles: [
            {
              title: "Approach to impact assessment",
              document: "WSI_EIS_Chapter_10_Approach_to_impact_assessment",
            },
          ],
        },
        {
          chapter: "Chapter 11",
          tableFiles: [
            {
              title: "Aircraft noise",
              document: "WSI_EIS_Chapter_11_Aircraft_noise",
            },
          ],
        },
        {
          chapter: "Chapter 12",
          tableFiles: [
            {
              title: "Air quality and greenhouse gas",
              document: "WSI_EIS_Chapter_12_Air_quality_and_greenhouse_gas",
            },
          ],
        },
        {
          chapter: "Chapter 13",
          tableFiles: [
            {
              title: "Aircraft hazards and risk",
              document: "WSI_EIS_Chapter_13_Aircraft_hazard_and_risk",
            },
          ],
        },
        {
          chapter: "Chapter 14",
          tableFiles: [
            {
              title: "Land use",
              document: "WSI_EIS_Chapter_14_Land_use",
            },
          ],
        },
        {
          chapter: "Chapter 15",
          tableFiles: [
            {
              title: "Landscape and visual amenity",
              document: "WSI_EIS_Chapter_15_Landscape_and_visual_amenity",
            },
          ],
        },
        {
          chapter: "Chapter 16",
          tableFiles: [
            {
              title: "Biodiversity",
              document: "WSI_EIS_Chapter_16_Biodiversity",
            },
          ],
        },
        {
          chapter: "Chapter 17",
          tableFiles: [
            {
              title: "Heritage",
              document: "WSI_EIS_Chapter_17_Heritage",
            },
          ],
        },
        {
          chapter: "Chapter 18",
          tableFiles: [
            {
              title: "Social",
              document: "WSI_EIS_Chapter_18_Social",
            },
          ],
        },
        {
          chapter: "Chapter 19",
          tableFiles: [
            {
              title: "Economic",
              document: "WSI_EIS_Chapter_19_Economic",
            },
          ],
        },
        {
          chapter: "Chapter 20",
          tableFiles: [
            {
              title: "Human health",
              document: "WSI_EIS_Chapter_20_Human_health",
            },
          ],
        },
        {
          chapter: "Chapter 21",
          tableFiles: [
            {
              title: "Facilitated impacts",
              document: "WSI_EIS_Chapter_21_Facilitated_impacts",
            },
          ],
        },
        {
          chapter: "Chapter 22",
          tableFiles: [
            {
              title: "Cumulative impacts",
              document: "WSI_EIS_Chapter_22_Cumulative_impacts",
            },
          ],
        },
        {
          chapter: "Chapter 23",
          tableFiles: [
            {
              title: "Matters of National Environmental Significance",
              document: "WSI_EIS_Chapter_23_MNES",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Part D	EIS synthesis",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Part D	EIS synthesis",
      groupHeadingId: "part-d",
      consolidatedChapters: {
        title: "Complete Part D - EIS Synthesis",
        document: "WSI_EIS_Part_D_EIS_synthesis",
      },
      chapters: [
        {
          chapter: "Chapter 24",
          tableFiles: [
            {
              title: "Mitigation and environmental management",
              document: "WSI_EIS_Chapter_24_Mitigation_and_management",
            },
          ],
        },
        {
          chapter: "Chapter 25",
          tableFiles: [
            {
              title: "Conclusion",
              document: "WSI_EIS_Chapter_25_Conclusion",
            },
          ],
        },
        {
          chapter: "Chapter 26",
          tableFiles: [
            {
              title: "References",
              document: "WSI_EIS_Chapter_26_References",
            },
          ],
        },
        {
          chapter: "Appendix A",
          tableFiles: [
            {
              title: "Proponent details and environmental record",
              document: "WSI_EIS_Appendix_A",
            },
          ],
        },
        {
          chapter: "Appendix B",
          tableFiles: [
            {
              title: "EIS team",
              document: "WSI_EIS_Appendix_B",
            },
          ],
        },
        {
          chapter: "Appendix C",
          tableFiles: [
            {
              title: "EIS guidelines",
              document: "WSI_EIS_Appendix_C",
            },
          ],
        },
        {
          chapter: "Appendix D",
          tableFiles: [
            {
              title:
                "List of persons and agencies consulted during the preparation of the draft EIS",
              document: "WSI_EIS_Appendix_D",
            },
          ],
        },
        {
          chapter: "Appendix E",
          tableFiles: [
            {
              title: "Project coordinates",
              document: "WSI_EIS_Appendix_E",
            },
          ],
        },
        {
          chapter: "Appendix F",
          tableFiles: [
            {
              title:
                "Background to the Western Sydney International noise insulation and property acquisition policy",
              document: "WSI_EIS_Appendix_F",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Technical papers",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Technical papers",
      groupHeadingId: "technical-papers",
      chapters: [
        {
          chapter: "Technical paper 1",
          tableFiles: [
            {
              title: "Aircraft noise",
              document: "Technical_paper_1_Aircraft_noise_Oct-2023",
            },
          ],
        },
        {
          chapter: "Technical paper 2",
          tableFiles: [
            {
              title: "Air quality",
              document: "Technical_paper_2_Air_quality",
            },
          ],
        },
        {
          chapter: "Technical paper 3",
          tableFiles: [
            {
              title: "Greenhouse gas emissions",
              document: "Technical_paper_3_Greenhouse_gas",
            },
          ],
        },
        {
          chapter: "Technical paper 4",
          tableFiles: [
            {
              title: "Hazard and risk",
              document: "Technical_paper_4_Hazard_and_risk",
            },
          ],
        },
        {
          chapter: "Technical paper 5",
          tableFiles: [
            {
              title: "Wildlife strike risk",
              document: "Technical_paper_5_Wildife_strike",
            },
          ],
        },
        {
          chapter: "Technical paper 6",
          tableFiles: [
            {
              title: "Land use and planning",
              document: "Technical_paper_6_Land_use_and_planning",
            },
          ],
        },
        {
          chapter: "Technical paper 7",
          tableFiles: [
            {
              title: "Landscape and visual impact",
              document: "Technical_paper_7_Landscape_and_visual_amenity",
            },
          ],
        },
        {
          chapter: "Technical paper 8",
          tableFiles: [
            {
              title: "Biodiversity",
              document: "Technical_paper_8_Biodiversity",
            },
          ],
        },
        {
          chapter: "Technical paper 9",
          tableFiles: [
            {
              title: "Heritage",
              document: "Technical_paper_9_Heritage",
            },
          ],
        },
        {
          chapter: "Technical paper 10",
          tableFiles: [
            {
              title: "Social",
              document: "Technical_paper_10_Social",
            },
          ],
        },
        {
          chapter: "Technical paper 11",
          tableFiles: [
            {
              title: "Economic",
              document: "Technical_paper_11_Economic",
            },
          ],
        },
        {
          chapter: "Technical paper 12",
          tableFiles: [
            {
              title: "Human health",
              document: "Technical_paper_12_Human_health",
            },
          ],
        },
        {
          chapter: "Technical paper 13",
          tableFiles: [
            {
              title: "Facilitated changes",
              document: "Technical_paper_13_Facilitated_changes",
            },
          ],
        },
        {
          chapter: "Technical paper 14",
          tableFiles: [
            {
              title: "Greater Blue Mountains World Heritage Area",
              document: "Technical_paper_14_Greater_Blue_Mountains_WHA",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Technical paper",
          },
          { text: "Description" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Hard copy viewing locations",
    moduleComponentName: "EISLocations",
    moduleProps: {
      groupHeadingId: "eis-viewing-locations",
    },
  },
];

const EISPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default EISPage;
